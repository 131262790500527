<template>
  <div id="page-withdraw-wait">
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <div class="vx-card__title">
            <h4>
              รอทำรายการถอนเงิน
              <span class="uppercase" style="color: rgb(230, 0, 0)">{{
                this.$route.params.id
              }}</span>
            </h4>
            <br />
          </div>
        </div>
        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          class="mr-6"
          size="small"
          icon-pack="feather"
          icon="icon-refresh-cw"
          color="success"
          @click="get_data()"
          >ดึงรายการถอน</vs-button
        >
        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          class="mr-22"
          size="small"
          icon-pack="feather"
          icon="icon-trash"
          color="danger"
          @click="clearotp()"
          >ลบ cookie ธนาคาร</vs-button
        >
      </div>
      <vs-table stripe pagination max-items="20" search :data="data_wd">
        <template slot="thead">
          <vs-th sort-key="datetime">DATE/TIME</vs-th>
          <vs-th
            v-if="withdrawType == 'pending' || withdrawType == 'error' || withdrawType == 'pendingUFA'"
            sort-key="datetime"
            >SINCE</vs-th
          >
          <vs-th sort-key="username">USERNAME</vs-th>
          <vs-th sort-key="credit">CREDIT</vs-th>
          <vs-th sort-key="name">NAME</vs-th>
          <vs-th sort-key="bank">BANK</vs-th>
          <vs-th sort-key="info">INFO</vs-th>
          <vs-th> </vs-th>
          <vs-th sort-key="admin">ADMIN</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].date_time">
              {{
                moment(data[indextr].date_time).format("YYYY-MM-DD HH:mm:ss")
              }}
            </vs-td>

            <vs-td v-if="withdrawType == 'pending' || withdrawType == 'error' || withdrawType == 'pendingUFA'">
              {{ getElapsedTime(data[indextr].date_time) }}
            </vs-td>

            <vs-td class="text-danger"
              ><router-link :to="`/member/${data[indextr].username}`">{{
                data[indextr].username
              }}</router-link></vs-td
            >
            <vs-td :data="data[indextr].amount">
              {{ currency(data[indextr].amount) }}
            </vs-td>

            <vs-td :data="data[indextr].fullname">
              {{ data[indextr].fullname }}
            </vs-td>

            <vs-td :data="data[indextr].bank">
              {{ data[indextr].bank }}
            </vs-td>
            <vs-td :data="data[indextr].info">
              {{ data[indextr].info }}
            </vs-td>
            <vs-td>
              <vs-button
                v-if="withdrawType != 'pending' && withdrawType != 'pendingUFA'"
                :disabled="!$store.state.AppActiveUser.permissions.withdraw.action"
                class="mx-1"
                size="small"
                color="rgb(115, 103, 240)"
                type="filled"
                @click="
                  req_bank(
                    data[indextr].username,
                    data[indextr].amount,
                    data[indextr].bank_code,
                    data[indextr].bank_number,
                    data[indextr].wd_id
                  )
                "
                >ถอน
              </vs-button>
              <vs-button
                v-if="withdrawType != 'pending' && withdrawType != 'pendingUFA'"
                :disabled="!$store.state.AppActiveUser.permissions.withdraw.action"
                class="mx-1"
                size="small"
                color="success"
                type="filled"
                @click="
                  checkpopup_confirm(
                    data[indextr].wd_id,
                    data[indextr].fullname
                  )
                "
                >ยืนยัน
              </vs-button>
              <vs-button
                v-if="withdrawType != 'pendingUFA'"
                :disabled="!$store.state.AppActiveUser.permissions.withdraw.action"
                class="mx-1"
                size="small"
                color="rgb(62, 201, 214)"
                type="filled"
                @click="
                  req_return(
                    data[indextr].username,
                    data[indextr].amount,
                    data[indextr].bank_code,
                    data[indextr].bank_number,
                    data[indextr].wd_id
                  )
                "
                >คืน
              </vs-button>

              <vs-button
                v-if="withdrawType == 'pendingUFA'"
                :disabled="!$store.state.AppActiveUser.permissions.withdraw.action"
                class="mx-1"
                size="small"
                color="success"
                type="filled"
                @click="
                  req_split(
                    data[indextr].username,
                    data[indextr].amount,
                    data[indextr].bank_code,
                    data[indextr].bank_number,
                    data[indextr].wd_id
                  )
                "
                >
                <vx-tooltip
                 text="กดเพื่อยืนยันว่าตัดเครดิต UFA แล้ว ยอดถอนจะแบ่งเป็นยอดละ 5 แสนอัตโนมัติ"
                >
                  ตัดเครดิตแล้ว
                </vx-tooltip>
              </vs-button>


              <vs-button
                :disabled="!$store.state.AppActiveUser.permissions.withdraw.action"
                class="mx-1"
                size="small"
                color="danger"
                type="filled"
                @click="
                  req_cancel(
                    data[indextr].username,
                    data[indextr].amount,
                    data[indextr].bank_code,
                    data[indextr].bank_number,
                    data[indextr].wd_id
                  )
                "
                >ยกเลิก
              </vs-button>
            </vs-td>
            <vs-td
              :data="data[indextr].admin"
              v-if="data[indextr].admin == null"
              class="text-center"
            >
              -
            </vs-td>
            <vs-td :data="data[indextr].admin" v-else>
              {{ data[indextr].admin }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <!------------------------------------------------------------------- ยืนยัน popup------------------------------------------------------------------------------>
      <ConfirmPopup
        :popup_confirm="popup_confirm"
        :data_confirm="data_confirm"
        :set_store="set_store"
        :get_data="get_data"
        :clear_data="clear_data"
        :banktransferScb="banktransferScb"
        :banktransferGsb="banktransferGsb"
        :banktransferKtb="banktransferKtb"
        :banktransferBay="banktransferBay"
        :banktransferKbank="banktransferKbank"
        :banktransferKKP="banktransferKKP"
        :closePopupConfirm="closePopupConfirm"
      />
      <!---------------------------------------------------------------------ยืนยัน popup END--------------------------------------------------------------------->
      <ClearOtpPopup
        :popupotp="popupotp"
        :scb_bank="scb_bank"
        :gsb_bank="gsb_bank"
        :bay_bank="bay_bank"
        :ktb_bank="ktb_bank"
        :permissions="!$store.state.AppActiveUser.permissions.withdraw.action"
      />
      <!------------------------------------------------------------------- cler otp popup------------------------------------------------------------------------------>

      <!---------------------------------------------------------------------cler otp popup END--------------------------------------------------------------------->
      <!---------------------------------------------------------------------ถอน popup START--------------------------------------------------------------------->
      <vs-popup
        classContent="popup-example"
        title="เลือกธนาคาร"
        :active.sync="popup_wd"
      >
        <!-- SCB WEB -->
        <div v-if="scb_bank.length > 0">
          <p
            v-if="scb_bank[0].bank_status_web == true"
            class="text-center mt-2 mx-2"
            style="color: white"
          >
            ถอน SCB ผ่านเว็บ
          </p>
          <div v-if="scb_bank[0].bank_status_web == true">
            <vs-button
              :disabled="
                !$store.state.AppActiveUser.permissions.withdraw.action
              "
              class="my-1 mx-2"
              size="small"
              color="rgb(86, 2, 126)"
              type="filled"
              @click="
                generateToken(withdrawdata.wd_id, item.bank_id);
                req_scb_otp(
                  item.bank_id,
                  withdrawdata.bank_code,
                  withdrawdata.bank_number,
                  withdrawdata.amount
                );
              "
              v-for="(item, index) in scb_bank"
              :key="index + 'scb'"
              >SCB-{{ item.bank_id }}
            </vs-button>
          </div>
        </div>
        <br />

        <!-- SCB APP -->
        <div v-if="scb_bank.length > 0">
          <p
            v-if="scb_bank[0].bank_status_app == true"
            class="text-center my-2 mx-2"
            style="color: white"
          >
            ถอน SCB APP
          </p>
          <div v-if="scb_bank[0].bank_status_app == true">
            <vs-button
              :disabled="
                !$store.state.AppActiveUser.permissions.withdraw.action
              "
              class="my-1 mx-2"
              size="small"
              color="rgb(86, 2, 126)"
              type="filled"
              @click="
                generateToken(withdrawdata.wd_id, item.bank_id);
                verify_scb_app(
                  item.bank_id,
                  withdrawdata.bank_code,
                  withdrawdata.bank_number,
                  withdrawdata.amount,
                  withdrawdata.wd_id
                );
              "
              v-for="(item, index) in scb_bank"
              :key="index + 'scb'"
              >SCB-{{ item.bank_id }}
            </vs-button>
          </div>
        </div>

        <!-- GSB -->
        <div v-if="gsb_bank.length > 0">
        <p
          class="text-center mt-2 mx-2"
          style="color: white"
        >
          ถอน GSB
        </p>
        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          class="my-1 mx-2"
          size="small"
          color="rgb(236, 6, 141)"
          type="filled"
          @click="
            generateToken(withdrawdata.wd_id, item.bank_id);
            req_gsb_otp(
              item.bank_id,
              withdrawdata.bank_code,
              withdrawdata.bank_number,
              withdrawdata.amount
            );
          "
          v-for="(item, index) in gsb_bank"
          :key="index + 'gsb'"
          >GSB-{{ item.bank_id }}
        </vs-button>
        </div>

        <!-- BAY -->
        <div v-if="bay_bank.length > 0">
          <p
            v-if="bay_bank[0].bank_status == true"
            class="text-center mt-2 mx-2"
            style="color: white"
          >
            ถอน BAY
          </p>
          <div v-if="bay_bank[0].bank_status == true">
            <vs-button
              :disabled="
                !$store.state.AppActiveUser.permissions.withdraw.action
              "
              class="my-1 mx-2"
              size="small"
              color="rgb(255, 165, 0)"
              type="filled"
              @click="
                generateToken(withdrawdata.wd_id, item.bank_id);
                req_bay_otp(
                  item.bank_id,
                  withdrawdata.bank_code,
                  withdrawdata.bank_number,
                  withdrawdata.amount
                );
              "
              v-for="(item, index) in bay_bank"
              :key="index + 'bay'"
              >BAY-{{ item.bank_id }}
            </vs-button>
          </div>
        </div>
        <br />

        <!-- KTB -->
        <div v-if="ktb_bank.length > 0">
          <p
            v-if="ktb_bank[0].bank_status == true"
            class="text-center mt-2 mx-2"
            style="color: white"
          >
            ถอน KTB
          </p>
          <div v-if="ktb_bank[0].bank_status == true">
            <vs-button
              :disabled="
                !$store.state.AppActiveUser.permissions.withdraw.action
              "
              class="my-1 mx-2"
              size="small"
              color="#00a4e4"
              type="filled"
              @click="
                generateToken(withdrawdata.wd_id, item.bank_id);
                req_ktb_otp(
                  item.bank_id,
                  withdrawdata.bank_code,
                  withdrawdata.bank_number,
                  withdrawdata.amount
                );
              "
              v-for="(item, index) in ktb_bank"
              :key="index + 'ktb'"
              >KTB-{{ item.bank_id }}
            </vs-button>
          </div>
        </div>

        <!-- KBANK APP -->
        <div class="my-1" v-if="kbank_bank.length > 0">
          <p
            v-if="kbank_bank[0].bank_status_app == true"
            class="text-center mb-2 mx-2"
            style="color: white"
          >
            ถอน KBANK APP
          </p>
          <div v-if="kbank_bank[0].bank_status_app == true">
            <vs-button
              :disabled="
                !$store.state.AppActiveUser.permissions.withdraw.action
              "
              class="my-1 mx-2"
              size="small"
              color="green"
              type="filled"
              @click="
                generateToken(withdrawdata.wd_id, item.bank_id);
                verify_kbank_app(
                  item.bank_id,
                  withdrawdata.bank_code,
                  withdrawdata.bank_number,
                  withdrawdata.amount,
                  withdrawdata.wd_id
                );
              "
              v-for="(item, index) in kbank_bank"
              :key="index + 'kbank'"
              >KBANK-{{ item.bank_id }}
            </vs-button>
          </div>
        </div>

        <!-- KKP APP -->
        <div v-if="kkp_bank.length > 0">
          <p
            class="text-center my-2 mx-2"
            style="color: white"
          >
            ถอน KKP APP
          </p>
          <div>
            <vs-button
              :disabled="
                !$store.state.AppActiveUser.permissions.withdraw.action
              "
              class="my-1 mx-2"
              size="small"
              color="red"
              type="filled"
              @click="
                generateToken(withdrawdata.wd_id, item.bank_id);
                verify_kkp_app(
                  item.bank_id,
                  withdrawdata.bank_code,
                  withdrawdata.bank_number,
                  withdrawdata.amount,
                  withdrawdata.wd_id
                );
              "
              v-for="(item, index) in kkp_bank"
              :key="index + 'kkp'"
              >KKP-{{ item.bank_id }}
            </vs-button>
          </div>
        </div>

        <!-- PAYMENT -->
        <div v-if="withdrawSettings">
          <p class="text-center mt-4 mx-2" style="color: white">
            ถอน PAYMENT
          </p>
          <div>
            <vs-button
              v-if="withdrawSettings.withdraw_vizpay"
              :disabled="!($store.state.AppActiveUser.permissions.withdraw.action)"
              class="my-1 mx-2"
              size="small"
              color="#00a4e4"
              type="filled"
              @click="
                generateToken(withdrawdata.wd_id, 'PAYMENT');
                verifyPayment(
                  withdrawdata.bank_code,
                  withdrawdata.bank_number,
                  withdrawdata.amount,
                  'vizpay'
                );
              "
              >VIZPAY
            </vs-button>
            <vs-button
              v-if="withdrawSettings.withdraw_akpay"
              :disabled="!($store.state.AppActiveUser.permissions.withdraw.action)"
              class="my-1 mx-2"
              size="small"
              color="#00a4e4"
              type="filled"
              @click="
                generateToken(withdrawdata.wd_id, 'PAYMENT');
                verifyPayment(
                  withdrawdata.bank_code,
                  withdrawdata.bank_number,
                  withdrawdata.amount,
                  'akpay'
                );
              "
              >AKPAY
            </vs-button>
            <vs-button
              v-if="withdrawSettings.withdraw_hengpay"
              :disabled="!($store.state.AppActiveUser.permissions.withdraw.action)"
              class="my-1 mx-2"
              size="small"
              color="#00a4e4"
              type="filled"
              @click="
                generateToken(withdrawdata.wd_id, 'PAYMENT');
                verifyPayment(
                  withdrawdata.bank_code,
                  withdrawdata.bank_number,
                  withdrawdata.amount,
                  'hengpay'
                );
              "
              >HENGPAY
            </vs-button>
            <vs-button
              v-if="withdrawSettings.withdraw_lucky"
              :disabled="!($store.state.AppActiveUser.permissions.withdraw.action)"
              class="my-1 mx-2"
              size="small"
              color="#00a4e4"
              type="filled"
              @click="
                generateToken(withdrawdata.wd_id, 'PAYMENT');
                verifyPayment(
                  withdrawdata.bank_code,
                  withdrawdata.bank_number,
                  withdrawdata.amount,
                  'lucky'
                );
              "
              >LUCKY
            </vs-button>
            <vs-button
              v-if="withdrawSettings.withdraw_omegapay"
              :disabled="!($store.state.AppActiveUser.permissions.withdraw.action)"
              class="my-1 mx-2"
              size="small"
              color="#00a4e4"
              type="filled"
              @click="
                generateToken(withdrawdata.wd_id, 'PAYMENT');
                verifyPayment(
                  withdrawdata.bank_code,
                  withdrawdata.bank_number,
                  withdrawdata.amount,
                  'omegapay'
                );
              "
              >OMEGA
            </vs-button>
            <vs-button
              v-if="withdrawSettings.withdraw_pompay"
              :disabled="!($store.state.AppActiveUser.permissions.withdraw.action)"
              class="my-1 mx-2"
              size="small"
              color="#00a4e4"
              type="filled"
              @click="
                generateToken(withdrawdata.wd_id, 'PAYMENT');
                verifyPayment(
                  withdrawdata.bank_code,
                  withdrawdata.bank_number,
                  withdrawdata.amount,
                  'pompay'
                );
              "
              >POMPAY
            </vs-button>
            <vs-button
              v-if="withdrawSettings.withdraw_fastpay"
              :disabled="!($store.state.AppActiveUser.permissions.withdraw.action)"
              class="my-1 mx-2"
              size="small"
              color="#00a4e4"
              type="filled"
              @click="
                generateToken(withdrawdata.wd_id, 'FPAYMENT');
                verifyPayment(
                  withdrawdata.bank_code,
                  withdrawdata.bank_number,
                  withdrawdata.amount,
                  'fpayment'
                );
              "
              >FPAYMENT
            </vs-button>
            <vs-button
            v-if="withdrawSettings.withdraw_7p"
            :disabled="!($store.state.AppActiveUser.permissions.withdraw.action)"
            class="my-1 mx-2"
            size="small"
            color="#00a4e4"
            type="filled"
            @click="
              generateToken(withdrawdata.wd_id, '7P');
              verifyPayment(
                withdrawdata.bank_code,
                withdrawdata.bank_number,
                withdrawdata.amount,
                '7p'
              );
            "
            >7P
          </vs-button>
          <vs-button
            v-if="withdrawSettings.withdraw_crypto"
            :disabled="!($store.state.AppActiveUser.permissions.withdraw.action)"
            class="my-1 mx-2"
            size="small"
            color="#00a4e4"
            type="filled"
            @click="
              generateToken(withdrawdata.wd_id, 'crypto');
              verifyPayment(
                withdrawdata.bank_code,
                withdrawdata.bank_number,
                withdrawdata.amount,
                'crypto'
              );
            "
            >CRYPTO
          </vs-button>
          </div>
        </div>
      </vs-popup>

      <!-- POPUP SCB WEB -->
      <vs-popup
        classContent="popup-scb"
        title="ถอน SCB ยืนยัน OTP"
        :active.sync="popup_scb"
      >
        <br />
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>INFO</span>
            </div>
          </template>

          <vs-input placeholder="Info" v-model="scb_form.info_scb" disabled />
        </vx-input-group>

        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>REF</span>
            </div>
          </template>

          <vs-input placeholder="Ref" v-model="scb_form.ref_scb" />
        </vx-input-group>

        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>OTP</span>
            </div>
          </template>

          <vs-input
            ref="inputOtp"
            placeholder="OTP"
            v-model="scb_form.otp_scb"
          />
        </vx-input-group>
        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          @click="
            verify_scb_otp(
              withdrawdata.verifyPayment,
              withdrawdata.username,
              withdrawdata.amount,
              withdrawdata.wd_id
            )
          "
          color="primary"
          type="filled"
          >ยืนยันOTP</vs-button
        >
      </vs-popup>

      <!-- POPUP SCB APP -->
      <vs-popup
        classContent="popup-scb"
        title="ถอน SCB APP ยืนยัน"
        :active.sync="popup_scb_app"
      >
        <br />
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>ยอดเงินคงเหลือในบัญชี</span>
            </div>
          </template>

          <vs-input
            placeholder="ยอดเงินคงเหลือในบัญชี"
            v-model="scb_app_form.balance_scb"
          />
        </vx-input-group>

        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>INFO</span>
            </div>
          </template>

          <vs-input
            placeholder="Info"
            v-model="scb_app_form.info_scb"
            disabled
          />
        </vx-input-group>

        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          @click="
            confirm_scb_app(
              withdrawdata.bank_code,
              withdrawdata.username,
              withdrawdata.amount,
              withdrawdata.wd_id
            )
          "
          color="primary"
          type="filled"
          >ยืนยันการโอนเงิน</vs-button
        >
      </vs-popup>

      <!-- POPUP GSB -->
      <vs-popup
        classContent="popup-gsb"
        title="ถอน GSB ยืนยัน OTP"
        :active.sync="popup_gsb"
      >
        <br />
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>INFO</span>
            </div>
          </template>

          <vs-input placeholder="Info" v-model="gsb_form.info_gsb" disabled />
        </vx-input-group>

        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>REF</span>
            </div>
          </template>

          <vs-input placeholder="Ref" v-model="gsb_form.ref_gsb" />
        </vx-input-group>

        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>OTP</span>
            </div>
          </template>

          <vs-input
            ref="inputOtp"
            placeholder="OTP"
            v-model="gsb_form.otp_gsb"
          />
        </vx-input-group>
        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          @click="
            verify_gsb_otp(
              withdrawdata.bank_code,
              withdrawdata.bank_number,
              withdrawdata.username,
              withdrawdata.amount,
              withdrawdata.wd_id
            )
          "
          color="primary"
          type="filled"
          >ยืนยันOTP</vs-button
        >
      </vs-popup>

      <!-- POPUP BAY -->
      <vs-popup
        classContent="popup-bay"
        title="ถอน BAY ยืนยัน OTP"
        :active.sync="popup_bay"
      >
        <br />
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>INFO</span>
            </div>
          </template>

          <vs-input placeholder="Info" v-model="bay_form.info_bay" disabled />
        </vx-input-group>

        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>REF</span>
            </div>
          </template>

          <vs-input placeholder="Ref" v-model="bay_form.ref_bay" />
        </vx-input-group>

        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>OTP</span>
            </div>
          </template>

          <vs-input
            ref="inputOtp"
            placeholder="OTP"
            v-model="bay_form.otp_bay"
          />
        </vx-input-group>
        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          @click="
            verify_bay_otp(
              withdrawdata.username,
              withdrawdata.amount,
              withdrawdata.wd_id
            )
          "
          color="primary"
          type="filled"
          >ยืนยันOTP</vs-button
        >
      </vs-popup>

      <!-- POPUP KTB -->
      <vs-popup
        classContent="popup-ktb"
        title="ถอน KTB ยืนยัน OTP"
        :active.sync="popup_ktb"
      >
        <br />
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>INFO</span>
            </div>
          </template>

          <vs-input placeholder="Info" v-model="ktb_form.info_ktb" disabled />
        </vx-input-group>

        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>REF</span>
            </div>
          </template>

          <vs-input placeholder="Ref" v-model="ktb_form.ref_ktb" />
        </vx-input-group>

        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>OTP</span>
            </div>
          </template>

          <vs-input
            ref="inputOtp"
            placeholder="OTP"
            v-model="ktb_form.otp_ktb"
          />
        </vx-input-group>
        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          @click="
            verify_ktb_otp(
              withdrawdata.username,
              withdrawdata.amount,
              withdrawdata.wd_id
            )
          "
          color="primary"
          type="filled"
          >ยืนยัน OTP</vs-button
        >
      </vs-popup>

      <!-- POPUP SCB WEB -->
      <vs-popup
        classContent="popup-scb"
        title="ถอน SCB ยืนยัน OTP"
        :active.sync="popup_scb"
      >
        <br />
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>INFO</span>
            </div>
          </template>

          <vs-input placeholder="Info" v-model="scb_form.info_scb" disabled />
        </vx-input-group>

        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>REF</span>
            </div>
          </template>

          <vs-input placeholder="Ref" v-model="scb_form.ref_scb" />
        </vx-input-group>

        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>OTP</span>
            </div>
          </template>

          <vs-input
            ref="inputOtp"
            placeholder="OTP"
            v-model="scb_form.otp_scb"
          />
        </vx-input-group>
        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          @click="
            verify_scb_otp(
              withdrawdata.verifyPayment,
              withdrawdata.username,
              withdrawdata.amount,
              withdrawdata.wd_id
            )
          "
          color="primary"
          type="filled"
          >ยืนยันOTP</vs-button
        >
      </vs-popup>

      <!-- POPUP KBANK APP -->
      <vs-popup
        classContent="popup-scb"
        title="ถอน KBANK APP ยืนยัน"
        :active.sync="popup_kbank_app"
      >
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>INFO</span>
            </div>
          </template>

          <vs-input placeholder="Info" v-model="kbank_app_form.info" disabled />
        </vx-input-group>

        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          @click="
            confirm_kbank_app(
              withdrawdata.bank_code,
              withdrawdata.username,
              withdrawdata.amount,
              withdrawdata.wd_id
            )
          "
          color="primary"
          type="filled"
          >ยืนยันการโอนเงิน</vs-button
        >
      </vs-popup>

      <!-- POPUP PAYMENT -->
      <vs-popup
        classContent="popup-scb"
        title="ถอน PAYMENT"
        :active.sync="popup_payment"
      >
        <br />
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>INFO</span>
            </div>
          </template>

          <vs-input placeholder="Info" v-model="payment_form.info" disabled />
        </vx-input-group>
        <div v-if="paymentType == 'crypto'">
          <vs-select v-model="cryptoNetwork" class="w-full mb-6">
            <vs-select-item  :key="index" :value="item.network" :text="item.network" v-for="(item,index) in cryptoList" />
          </vs-select>
        </div>

        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          @click="
            confirmPayment(
              withdrawdata.bank_code,
              withdrawdata.username,
              withdrawdata.amount,
              withdrawdata.wd_id,
              paymentType
            )
          "
          color="primary"
          type="filled"
          >ยืนยันการโอนเงิน</vs-button
        >
      </vs-popup>

      <!-- POPUP KKP -->
      <KKPBankPopup
        :popup_kkp_app="popup_kkp_app"
        :kkp_app_form="kkp_app_form"
        :permissions="!$store.state.AppActiveUser.permissions.withdraw.action"
        :withdrawdata="withdrawdata"
        :verify_kkp_app="verify_kkp_app"
        :confirm_kkp_app="confirm_kkp_app"
      />

      <!---------------------------------------------------------------------ถอน popup END--------------------------------------------------------------------->
      <!-- POPUP RETRUN CREDIT -->
      <vs-popup
        classContent="popup-example"
        title="กรุณากรอกหมายเหตุ คืนเครดิต"
        :active.sync="popup_return"
      >
        <br />
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>Info</span>
            </div>
          </template>

          <vs-input placeholder="Info" v-model="info" />
        </vx-input-group>

        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          @click="
            returncredit(
              withdrawdata.username,
              withdrawdata.amount,
              withdrawdata.wd_id
            )
          "
          color="primary"
          type="filled"
          >ยืนยัน</vs-button
        >
      </vs-popup>

      <!-- POPUP CANCEL WD -->
      <vs-popup
        classContent="popup-example"
        title="กรุณากรอกหมายเหตุ ยกเลิกการถอน"
        :active.sync="popup_cancel"
      >
        <br />
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>Info</span>
            </div>
          </template>

          <vs-input placeholder="Info" v-model="info" />
        </vx-input-group>

        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          @click="cancel(withdrawdata.username, withdrawdata.wd_id)"
          color="primary"
          type="filled"
          >ยืนยัน
        </vs-button>
      </vs-popup>

      <!-- POPUP SPLIT WD -->
      <vs-popup
        classContent="popup-example"
        title="โปรดยืนยันว่าตัดเครดิต UFA แล้ว"
        :active.sync="popup_split"
      >
        <br />
        <h1 class="mb-5">
          กดเพื่อยืนยันว่าตัดเครดิต UFA แล้ว
        </h1>
        <h1 class="mb-10">
          ยอดถอนจะแบ่งเป็นยอดละ 5 แสนให้อัตโนมัติ
        </h1>


        <vs-button
          :disabled="
            !$store.state.AppActiveUser.permissions.withdraw.action
          "
          @click="split(withdrawdata.username, withdrawdata.wd_id)"
          color="primary"
          type="filled"
          >ยืนยันการตัดเครดิต
        </vs-button>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

import axios from '../../axios'
import moment from 'moment'

import ConfirmPopup from '@/components/withdraw/ConfirmPopup.vue'
import ClearOtpPopup from '@/components/withdraw/ClearOtpPopup.vue'
import KKPBankPopup from '@/components/withdraw/KKPBankPopup.vue'

export default {
  components: {
    AgGridVue,
    vSelect,
    moment,
    flatPickr,
    CellRendererActions,
    ConfirmPopup,
    ClearOtpPopup,
    KKPBankPopup
  },
  data () {
    return {
      withdrawType: this.$route.params.id,
      webname: process.env.VUE_APP_TITLE,
      scb: true,
      scb_app: true,
      ktb: true,
      popup_wd: false,
      popup_return: false,
      popup_cancel: false,
      popup_split: false,
      popup_scb: false,
      popup_gsb: false,
      popup_bay: false,
      popup_scb_app: false,
      popup_ktb: false,
      popup_kbank_app: false,
      popup_kkp_app: false,
      popup_payment: false,
      withdrawdata: {
        username: '',
        amount: '',
        bank_code: '',
        bank_number: '',
        wd_id: ''
      },
      scb_bank: [],
      get_scb_status: [],
      scb_form: {
        bank_id: '',
        ref_scb: '',
        otp_scb: '',
        info_scb: ''
      },
      get_scb_app: [],
      scb_app_form: {
        bank_id: '',
        balance_scb: '',
        info_scb: '',
        data_scb: ''
      },
      gsb_bank: [],
      get_gsb_status: [],
      gsb_form: {
        bank_id: '',
        ref_gsb: '',
        otp_gsb: '',
        info_gsb: '',
        from_acc_id: ''
      },
      bay_bank: [],
      get_bay_status: [],
      bay_form: {
        bank_id: '',
        ref_bay: '',
        otp_bay: '',
        info_bay: '',
        url: ''
      },
      ktb_bank: [],
      get_ktb_status: [],
      ktb_form: {
        bank_id: '',
        ref_ktb: '',
        otp_ktb: '',
        info_ktb: '',
        url: ''
      },
      kbank_bank: [],
      get_kbank_app: [],
      kbank_app_form: {
        bank_id: '',
        info: '',
        data_kbank: ''
      },
      kkp_bank: [],
      get_kkp_app: [],
      kkp_app_form: {
        bank_id: '',
        info: '',
        data_kkp: ''
      },
      payment_form: {
        balance: '',
        info: ''
      },
      info: '',
      status_wd: [],
      get_data_wd: [],
      data_wd: [],
      count_staff_wd: [],
      components: '',
      popupotp: false,
      popup_confirm: false,
      data_confirm: {
        wd_id: '',
        name: ''
      },
      clearcookie: '',
      banktransferBay: [],
      banktransferGsb: [],
      banktransferKtb: [],
      banktransferScb: [],
      banktransferKbank: [],
      banktransferKKP: [],
      payment_setting: false,
      withdrawSettings: null,
      paymentType: 'vizpay',
      cryptoNetwork: '',
      cryptoList: []
    }
  },
  watch: {
    async $route (to, from) {
      if (to.params.id !== from.params.id) {
        window.location.reload()
      }
    }
  },
  methods: {
    getElapsedTime (timestamp) {
      const providedDate = new Date(timestamp)
      const currentDate = new Date()
      const elapsedTime = currentDate.getTime() - providedDate.getTime()

      // Calculate the elapsed time in seconds
      const elapsedSeconds = Math.floor(elapsedTime / 1000)

      if (elapsedSeconds < 60) {
        return `${elapsedSeconds} sec${elapsedSeconds === 1 ? '' : 's'} ago`
      }

      // Calculate the elapsed time in minutes
      const elapsedMinutes = Math.floor(elapsedSeconds / 60)

      if (elapsedMinutes < 60) {
        return `${elapsedMinutes} min${elapsedMinutes === 1 ? '' : 's'} ago`
      }

      // Calculate the elapsed time in hours
      const elapsedHours = Math.floor(elapsedMinutes / 60)

      if (elapsedHours < 24) {
        return `${elapsedHours} hour${elapsedHours === 1 ? '' : 's'} ago`
      }

      // Calculate the elapsed time in days
      const elapsedDays = Math.floor(elapsedHours / 24)
      return `${elapsedDays} day${elapsedDays === 1 ? '' : 's'} ago`
    },
    playSound () {
      const audio = new Audio(
        'http://soundbible.com/mp3/Two%20Tone%20Doorbell-SoundBible.com-1238551671.mp3'
      )
      audio.volume = 0.3
      audio.play()
    },
    async update_bankshow (bank_show, bankname) {
      if (bank_show === 1 || bank_show === true) {
        bank_show = false
      } else {
        bank_show = true
      }
      await axios
        .post('withdraw/updatestatus', {
          bank: bankname,
          show: bank_show
        })
        .then((response) => (this.onoff_status = response.data))
      if (this.onoff_status === true) {
        this.$vs.notify({
          time: 3000,
          color: bank_show === true ? 'success' : 'warning',
          position: 'top-right',
          icon: bank_show === true ? 'check_box' : 'error',
          title:
            bank_show === true ? `เปิดแสดงหน้าเว็บบัญชีถอน : ${bankname} สำเร็จ` : `ปิดแสดงหน้าเว็บบัญชีถอน : ${bankname} สำเร็จ`
        })
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title:
            bank_show === true ? `เปิดแสดงหน้าเว็บบัญชีถอน : ${bankname}หน้าเว็บไม่สำเร็จ!!` : `ปิดแสดงหน้าเว็บบัญชีถอน : ${bankname}หน้าเว็บไม่สำเร็จ!!`,
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
      }
    },
    async checkpopup_confirm (data, name) {
      await axios
        .get('settingwallet/banktransfer/scb')
        .then((response) => (this.banktransferScb = response.data))
      await axios
        .get('settingwallet/banktransfer/bay')
        .then((response) => (this.banktransferBay = response.data))
      await axios
        .get('settingwallet/banktransfer/gsb')
        .then((response) => (this.banktransferGsb = response.data))
      await axios
        .get('settingwallet/banktransfer/ktb')
        .then((response) => (this.banktransferKtb = response.data))
      await axios
        .get('settingwallet/banktransfer/kbank')
        .then((response) => (this.banktransferKbank = response.data))
      await axios
        .get('settingwallet/banktransfer/kkp')
        .then((response) => (this.banktransferKKP = response.data))
      this.popup_confirm = true
      this.data_confirm.wd_id = data
      this.data_confirm.name = name
    },
    closePopupConfirm () {
      this.popup_confirm = false
    },
    currency (amount) {
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        return `${amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }
    },
    set_store () {
      this.status_wd = true
    },
    async get_data () {
      // GET staff.length withdraw
      await axios
        .get('settingwithdraw/settingwithdraw')
        .then((response) => (this.count_staff_wd = response.data))

      // GET  waiting withdraw data
      await axios
        .get(
          `withdraw/waitwithdraw/${this.count_staff_wd[0].setting_data}/${this.withdrawType}`
        )
        .then((response) => (this.get_data_wd = response.data))
      this.data_wd = this.get_data_wd.data
    },
    async get_scb_bank () {
      // GET SCB BANK
      await axios
        .get('settingwallet/banktransfer/scb')
        .then((response) => (this.scb_bank = response.data))
    },
    async get_gsb_bank () {
      // GET GSB BANK
      await axios
        .get('settingwallet/banktransfer/gsb')
        .then((response) => (this.gsb_bank = response.data))
    },
    async get_bay_bank () {
      // GET BAY BANK
      await axios
        .get('settingwallet/banktransfer/bay')
        .then((response) => (this.bay_bank = response.data))
    },
    async get_ktb_bank () {
      // GET ktb BANK
      await axios
        .get('settingwallet/banktransfer/ktb')
        .then((response) => (this.ktb_bank = response.data))
    },
    async get_kbank_bank () {
      // GET KBANK bank
      await axios
        .get('settingwallet/banktransfer/kbank')
        .then((response) => (this.kbank_bank = response.data))
    },
    async get_kkp_bank () {
      // GET KKP bank
      await axios
        .get('settingwallet/banktransfer/kkp')
        .then((response) => (this.kkp_bank = response.data))
    },
    async clearotp () {
      this.popupotp = true
      this.get_scb_bank()
      this.get_gsb_bank()
      this.get_bay_bank()
      this.get_ktb_bank()
      this.get_kbank_bank()
      this.get_kkp_bank()
    },
    async req_bank (username, amount, bank_code, bank_number, wd_id) {
      this.popup_wd = true
      this.withdrawdata.username = username
      this.withdrawdata.amount = amount
      this.withdrawdata.bank_code = bank_code
      this.withdrawdata.bank_number = bank_number
      this.withdrawdata.wd_id = wd_id
      await this.get_scb_bank()
      await this.get_gsb_bank()
      await this.get_bay_bank()
      await this.get_ktb_bank()
      await this.get_kbank_bank()
      await this.get_kkp_bank()
    },
    clear_data () {
      //clear scb  data
      this.get_scb_status = []
      this.scb_form.bank_id = ''
      this.scb_form.ref_scb = ''
      this.scb_form.otp_scb = ''
      this.scb_form.info_scb = ''
      //clear gsb data
      this.get_gsb_status = []
      this.gsb_form.bank_id = ''
      this.gsb_form.ref_gsb = ''
      this.gsb_form.otp_gsb = ''
      this.gsb_form.info_gsb = ''
      this.gsb_form.from_acc_id = ''
      //clear bay data
      this.get_bay_status = []
      this.bay_form.bank_id = ''
      this.bay_form.ref_bay = ''
      this.bay_form.otp_bay = ''
      this.bay_form.info_bay = ''
      this.bay_form.url = ''
      //clear scb app data
      this.get_scb_app = []
      this.scb_app_form.bank_id = ''
      this.scb_app_form.balance_scb = ''
      this.scb_app_form.info_scb = ''
      this.scb_app_form.data_scb = ''
      //clear ktb data
      this.get_ktb_status = []
      this.ktb_form.bank_id = ''
      this.ktb_form.ref_ktb = ''
      this.ktb_form.otp_ktb = ''
      this.ktb_form.info_ktb = ''
      this.ktb_form.url = ''
      //clear kbank app data
      this.get_kbank_app = []
      this.kbank_app_form.bank_id = ''
      this.kbank_app_form.info = ''
      this.kbank_app_form.data_kbank = ''
      //clear kkp app data
      this.get_kkp_app = []
      this.kkp_app_form.bank_id = ''
      this.kkp_app_form.info = ''
      this.kkp_app_form.data_kkp = ''
      // clear payment data
      this.get_payment = []
      this.payment_form = {
        balance: '',
        info: ''
      }
      //clear data
      this.info = ''
      this.status_wd = []
    },
    req_return (username, amount, bank_code, bank_number, wd_id) {
      this.withdrawdata.username = username
      this.withdrawdata.amount = amount
      this.withdrawdata.bank_code = bank_code
      this.withdrawdata.bank_number = bank_number
      this.withdrawdata.wd_id = wd_id
      this.popup_return = true
    },
    async returncredit (username, amount, wd_id) {
      this.popup_return = false
      await axios
        .post('withdraw/returnwithdraw', {
          username,
          amount,
          wd_id,
          info: this.info
        })
        .then((response) => (this.status_wd = response.data))
      if (this.status_wd.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'คืนเครดิตสำเร็จ',
          text: this.status_wd.info
        })
        this.set_store()
        this.get_data()
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'คืนเครดิตไม่สำเร็จ',
          text: this.status_wd.info
        })
        this.clear_data()
      }
    },
    req_cancel (username, amount, bank_code, bank_number, wd_id) {
      this.withdrawdata.username = username
      this.withdrawdata.amount = amount
      this.withdrawdata.bank_code = bank_code
      this.withdrawdata.bank_number = bank_number
      this.withdrawdata.wd_id = wd_id
      this.popup_cancel = true
    },
    req_split (username, amount, bank_code, bank_number, wd_id) {
      this.withdrawdata.username = username
      this.withdrawdata.amount = amount
      this.withdrawdata.bank_code = bank_code
      this.withdrawdata.bank_number = bank_number
      this.withdrawdata.wd_id = wd_id
      this.popup_split = true
    },
    async split (username, wd_id) {
      this.popup_split = false
      await axios
        .post('withdraw/splitwithdraw', {
          username,
          wd_id
        })
        .then((response) => (this.status_wd = response.data))
      if (this.status_wd.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ยืนยันการตัดเครดิตสำเร็จ',
          text: this.status_wd.info
        })
        this.clear_data()
        this.set_store()
        this.get_data()
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ยืนยันการตัดเครดิตไม่สำเร็จ',
          text: this.status_wd.info
        })
        this.clear_data()
      }
    },
    async cancel (username, wd_id) {
      this.popup_cancel = false
      await axios
        .post('withdraw/cancelwithdraw', {
          wd_id,
          info: this.info
        })
        .then((response) => (this.status_wd = response.data))
      if (this.status_wd.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ยกเลิกถอนสำเร็จ',
          text: this.status_wd.info
        })
        this.clear_data()
        this.set_store()
        this.get_data()
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ยกเลิกถอนไม่สำเร็จ',
          text: this.status_wd.info
        })
        this.clear_data()
      }
    },
    //REQUEST SCB OTP
    async req_scb_otp (bankid, bank_code, bank_number, amount) {
      this.popup_wd = false
      this.popup_scb = true
      this.scb_form.info_scb = 'Loading...'
      this.scb_form.ref_scb = 'Loading...'
      this.scb_form.bank_id = bankid
      await axios
        .post('withdraw/transfer/scb', {
          bank_id: bankid,
          bank_code,
          bank_number,
          amount
        })
        .then((response) => (this.get_scb_status = response.data))
      if (this.get_scb_status.success === true) {
        this.scb_form.ref_scb = this.get_scb_status.ref
        this.scb_form.info_scb = `โอนไปบัญชี ${this.get_scb_status.toAcc}`
        if ('toName' in this.get_scb_status) {
          this.scb_form.info_scb = `โอนไปบัญชี ${this.get_scb_status.toAcc} บัญชีชื่อ ${this.get_scb_status.toName}`
        }
      } else {
        this.popup_scb = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ไม่สามารถขอ OTP ได้',
          text: 'กรุณาลองใหม่อีกครั้ง'
        })
        this.clear_data()
        this.set_store()
      }
    },
    //VERIFY SCB OTP
    async verify_scb_otp (bank_code, username, amount, wd_id) {
      this.$vs.loading()

      const token = localStorage.getItem('token')

      await axios
        .post('withdraw/transfer/scb/confirm', {
          bank_id: this.scb_form.bank_id,
          bank_code,
          otp: this.scb_form.otp_scb,
          wd_id,
          token
        })
        .then((response) => (this.status_wd = response.data))
      if (this.status_wd.success === true) {
        this.$vs.loading.close()
        this.popup_scb = false
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ถอนเงินสำเร็จ',
          text: `ยูสเซอร์ ${username} จำนวนเงิน ${amount}`
        })
        this.clear_data()
        this.set_store()
        this.get_data()
      } else if (!this.status_wd.success && this.status_wd.failToken) {
        this.$vs.loading.close()
        this.popup_scb = false
        this.notifyFailToken()
        this.clear_data()
        this.set_store()
      } else {
        this.$vs.loading.close()
        this.popup_scb = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ถอนเงินไม่สำเร็จ',
          text: `ยูสเซอร์ ${username} จำนวนเงิน ${amount}`
        })
        this.clear_data()
        this.set_store()
      }
    }, //REQUEST GSB OTP
    async req_gsb_otp (bankid, bank_code, bank_number, amount) {
      this.popup_wd = false
      this.popup_gsb = true
      this.gsb_form.info_gsb = 'Loading...'
      this.gsb_form.ref_gsb = 'Loading...'
      this.gsb_form.bank_id = bankid
      await axios
        .post('withdraw/transfer/gsb', {
          bank_id: bankid,
          bank_code,
          bank_number,
          amount
        })
        .then((response) => (this.get_gsb_status = response.data))
      if (this.get_gsb_status.success === true) {
        this.gsb_form.ref_gsb = this.get_gsb_status.ref
        this.gsb_form.from_acc_id = this.get_gsb_status.fromAccountId
        if ('toAcc' in this.get_gsb_status) {
          this.gsb_form.info_gsb = `โอนไปบัญชี ${this.get_gsb_status.toAcc} บัญชีชื่อ ${this.get_gsb_status.toName}`
        } else {
          this.gsb_form.info_gsb = `โอนไปบัญชีชื่อ ${this.get_gsb_status.toName}`
        }
      } else {
        this.popup_gsb = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ไม่สามารถขอ OTP ได้',
          text: 'กรุณาลองใหม่อีกครั้ง'
        })
        this.clear_data()
        this.set_store()
      }
    }, // VERIFY GSB OTP
    async verify_gsb_otp (bank_code, bank_number, username, amount, wd_id) {
      this.$vs.loading()

      const token = localStorage.getItem('token')

      await axios
        .post('withdraw/transfer/gsb/confirm', {
          bank_id: this.gsb_form.bank_id,
          bank_code,
          bank_number,
          otp: this.gsb_form.otp_gsb,
          amount,
          fromAccountId: this.gsb_form.from_acc_id,
          wd_id,
          token
        })
        .then((response) => (this.status_wd = response.data))
      if (this.status_wd.success === true) {
        this.$vs.loading.close()
        this.popup_gsb = false
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ถอนเงินสำเร็จ',
          text: `ยูสเซอร์${username}จำนวนเงิน${amount}`
        })
        this.clear_data()
        this.set_store()
        this.get_data()
      } else if (!this.status_wd.success && this.status_wd.failToken) {
        this.$vs.loading.close()
        this.popup_gsb = false
        this.notifyFailToken()
        this.clear_data()
        this.set_store()
      } else {
        this.$vs.loading.close()
        this.popup_gsb = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ถอนเงินไม่สำเร็จ',
          text: `ยูสเซอร์${username}จำนวนเงิน${amount}`
        })
        this.clear_data()
        this.set_store()
      }
    }, // REQUEST BAY OTP
    async req_bay_otp (bankid, bank_code, bank_number, amount) {
      this.popup_wd = false
      this.popup_bay = true
      this.bay_form.info_bay = 'Loading...'
      this.bay_form.ref_bay = 'Loading...'
      this.bay_form.bank_id = bankid
      await axios
        .post('withdraw/transfer/bay', {
          bank_id: bankid,
          bank_code,
          bank_number,
          amount
        })
        .then((response) => (this.get_bay_status = response.data))
      if (this.get_bay_status.success === true) {
        this.bay_form.ref_bay = this.get_bay_status.ref
        this.bay_form.info_bay = `โอนไปบัญชี ${this.get_bay_status.toAcc}`
        this.bay_form.url = this.get_bay_status.url
      } else {
        this.popup_bay = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ไม่สามารถขอ OTP ได้',
          text: 'กรุณาลองใหม่อีกครั้ง'
        })
        this.clear_data()
        this.set_store()
      }
    }, // VERIFY BAY OTP
    async verify_bay_otp (username, amount, wd_id) {
      this.$vs.loading()

      const token = localStorage.getItem('token')

      await axios
        .post('withdraw/transfer/bay/confirm', {
          bank_id: this.bay_form.bank_id,
          otp: this.bay_form.otp_bay,
          url: this.bay_form.url,
          wd_id,
          token
        })
        .then((response) => (this.status_wd = response.data))
      if (this.status_wd.success === true) {
        this.$vs.loading.close()
        this.popup_bay = false
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ถอนเงินสำเร็จ',
          text: `ยูสเซอร์${username}จำนวนเงิน${amount}`
        })
        this.clear_data()
        this.set_store()
        this.get_data()
      } else if (!this.status_wd.success && this.status_wd.failToken) {
        this.$vs.loading.close()
        this.popup_bay = false
        this.notifyFailToken()
        this.clear_data()
        this.set_store()
      } else {
        this.$vs.loading.close()
        this.popup_bay = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ถอนเงินไม่สำเร็จ',
          text: `ยูสเซอร์${username}จำนวนเงิน${amount}`
        })
        this.clear_data()
        this.set_store()
      }
    },
    // VERIFY SCB APP
    async verify_scb_app (bankid, bank_code, bank_number, amount, wd_id) {
      const token = localStorage.getItem('token')

      this.popup_wd = false
      this.popup_scb_app = true
      this.scb_app_form.info_scb = 'Loading...'
      this.scb_app_form.balance_scb = 'Loading...'
      this.scb_app_form.bank_id = bankid
      await axios
        .post('withdraw/transfer/scbapp', {
          bank_id: bankid,
          bank_code,
          bank_number,
          amount,
          wd_id,
          token
        })
        .then((response) => (this.get_scb_app = response.data))
      if (this.get_scb_app.profile.status.code === 1000) {
        this.scb_app_form.balance_scb = this.get_scb_app.profile.accountBalance
        if (this.get_scb_app.verify.data) {
          this.scb_app_form.info_scb = `โอนไปบัญชี ${this.get_scb_app.verify.data.accountToName} เลขบัญชี ${this.get_scb_app.verify.data.accountTo}`
          this.scb_app_form.data_scb = this.get_scb_app.verify
        } else {
          this.scb_app_form.info_scb = `${this.get_scb_app.verify.status.header} ${this.get_scb_app.verify.status.description}`
        }
      } else {
        this.popup_scb_app = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: this.get_scb_app.profile.status.header,
          text: this.get_scb_app.profile.status.description
        })
        this.clear_data()
        this.set_store()
      }
    },
    // CONFIRM SCB APP
    async confirm_scb_app (bank_code, username, amount, wd_id) {
      this.$vs.loading()
      const token = localStorage.getItem('token')
      await axios
        .post('withdraw/transfer/scbapp/confirm', {
          bank_id: this.scb_app_form.bank_id,
          bank_code,
          amount,
          transfer_verify: this.scb_app_form.data_scb,
          wd_id,
          token
        })
        .then((response) => (this.status_wd = response.data))
      if (this.status_wd.confirm.status.code === 1000) {
        this.$vs.loading.close()
        this.popup_scb_app = false
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ถอนเงินสำเร็จ',
          text: `ยูสเซอร์ ${username} จำนวนเงิน ${amount}`
        })
        this.clear_data()
        this.set_store()
        this.get_data()
      } else {
        this.$vs.loading.close()
        this.popup_scb = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: this.status_wd.confirm.status.header,
          text: this.status_wd.confirm.status.description
        })
        this.clear_data()
        this.set_store()
      }
    },
    // VERIFY KBANK APP
    async verify_kbank_app (bankid, bank_code, bank_number, amount, wd_id) {
      const token = localStorage.getItem('token')

      this.popup_wd = false
      this.popup_kbank_app = true
      this.kbank_app_form.info = 'Loading...'
      this.kbank_app_form.bank_id = bankid

      await axios
        .post('withdraw/transfer/kbankapp', {
          bank_id: bankid,
          bank_code,
          bank_number,
          amount,
          wd_id,
          token
        })
        .then((response) => {
          this.get_kbank_app = response.data
        })

      if (this.get_kbank_app.status) {
        const { accountToName, accountTo } = this.get_kbank_app.data.info
        this.kbank_app_form.info = 'กรุณายืนยันการโอนเงิน'
        if (accountToName && accountTo) {
          this.kbank_app_form.info = `โอนไปบัญชี ${accountToName} เลขบัญชี ${accountTo}`
          this.kbank_app_form.data_kbank = this.get_kbank_app.data.handle
        }
      } else {
        this.popup_kbank_app = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          text: this.status_wd.message
        })
        this.clear_data()
        this.set_store()
      }
    },
    // CONFIRM KBANK APP
    async confirm_kbank_app (bank_code, username, amount, wd_id) {
      this.$vs.loading()
      const token = localStorage.getItem('token')
      await axios
        .post('withdraw/transfer/kbankapp/confirm', {
          bank_id: this.kbank_app_form.bank_id,
          bank_code,
          amount,
          handle: this.kbank_app_form.data_kbank,
          wd_id,
          token
        })
        .then((response) => (this.status_wd = response.data))
      if (this.status_wd.status) {
        this.$vs.loading.close()
        this.popup_kbank_app = false
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ถอนเงินสำเร็จ',
          text: this.status_wd.message
        })
        this.clear_data()
        this.set_store()
        this.get_data()
      } else {
        this.$vs.loading.close()
        this.popup_kbank = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          text: this.status_wd.message
        })
        this.clear_data()
        this.set_store()
      }
    },
    // VERIFY KKP APP
    async verify_kkp_app (bankid, bank_code, bank_number, amount, wd_id) {
      const token = localStorage.getItem('token')

      this.popup_wd = false
      this.popup_kkp_app = true
      this.kkp_app_form.info = 'Loading...'
      this.kkp_app_form.bank_id = bankid

      await axios
        .post('withdraw/v2/transfer/verify/kkp', {
          bank_id: bankid,
          bank_code,
          bank_number,
          amount,
          wd_id,
          token
        })
        .then((response) => {
          this.get_kkp_app = response.data
        })

      if (this.get_kkp_app.status) {
        const { message } = this.get_kkp_app
        this.kkp_app_form.info = message
      } else {
        this.popup_kkp_app = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          text: this.get_kkp_app.message
        })
        this.clear_data()
        this.set_store()
      }
    },
    // CONFIRM KKP APP
    async confirm_kkp_app (bank_code, bank_number, amount, wd_id) {
      this.$vs.loading()
      const token = localStorage.getItem('token')
      await axios
        .post('withdraw/v2/transfer/confirm/kkp', {
          bank_id: this.kkp_app_form.bank_id,
          bank_number,
          bank_code,
          amount,
          wd_id,
          token
        })
        .then((response) => (this.status_wd = response.data))
      if (this.status_wd.status) {
        this.$vs.loading.close()
        this.popup_kkp_app = false
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ถอนเงินสำเร็จ',
          text: this.status_wd.message
        })
        this.clear_data()
        this.set_store()
        this.get_data()
      } else {
        this.$vs.loading.close()
        this.popup_kkp = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          text: this.status_wd.message
        })
        this.clear_data()
        this.set_store()
      }
    },
    // KTB
    async req_ktb_otp (bankid, bank_code, bank_number, amount) {
      this.popup_wd = false
      this.popup_ktb = true
      this.ktb_form.info_ktb = 'Loading...'
      this.ktb_form.ref_ktb = 'Loading...'
      this.ktb_form.bank_id = bankid
      await axios
        .post('withdraw/transfer/ktb', {
          bank_id: bankid,
          bank_code,
          bank_number,
          amount
        })
        .then((response) => (this.get_ktb_status = response.data))
      if (this.get_ktb_status.success === true) {
        this.ktb_form.ref_ktb = this.get_ktb_status.ref
        this.ktb_form.info_ktb = `โอนไปบัญชี ${this.get_ktb_status.toAcc}`
        this.ktb_form.url = this.get_ktb_status.url
      } else {
        this.popup_ktb = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ไม่สามารถขอ OTP ได้',
          text: 'กรุณาลองใหม่อีกครั้ง'
        })
        this.clear_data()
        this.set_store()
      }
    },
    async verify_ktb_otp (username, amount, wd_id) {
      this.$vs.loading()

      const token = localStorage.getItem('token')

      await axios
        .post('withdraw/transfer/ktb/confirm', {
          bank_id: this.ktb_form.bank_id,
          otp: this.ktb_form.otp_ktb,
          url: this.ktb_form.url,
          wd_id,
          token
        })
        .then((response) => (this.status_wd = response.data))
      if (this.status_wd.success === true) {
        this.$vs.loading.close()
        this.popup_ktb = false
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ถอนเงินสำเร็จ',
          text: `ยูสเซอร์: ${username} จำนวนเงิน: ${amount}`
        })
        this.clear_data()
        this.set_store()
        this.get_data()
      } else if (!this.status_wd.success && this.status_wd.failToken) {
        this.$vs.loading.close()
        this.popup_ktb = false
        this.notifyFailToken()
        this.clear_data()
        this.set_store()
      } else {
        this.$vs.loading.close()
        this.popup_ktb = false
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ถอนเงินไม่สำเร็จ',
          text: `ยูสเซอร์: ${username} จำนวนเงิน: ${amount}`
        })
        this.clear_data()
        this.set_store()
      }
    },
    notifyFailToken () {
      this.$vs.notify({
        time: 8000,
        color: 'warning',
        position: 'top-right',
        icon: 'warning',
        title: 'โปรดลองใหม่อีกครั้ง',
        text: 'สามารถทำรายการ ขอ otp และถอน ได้แค่ทีละ 1 ยอดเท่านั้น'
      })
    },
    async generateToken (wd_id, bank_id) {
      const self = this

      const token = await axios
        .post('withdraw/transfer/token', {
          wd_id,
          bank_id
        })
        .catch(() => {
          self.$vs.notify({
            time: 8000,
            color: 'danger',
            position: 'top-right',
            icon: 'error',
            title: 'พบปัญหาด้านความปลอดภัย',
            text: 'โปรดลองใหม่อีกครั้ง หรือโปรดติดต่อโปรแกรมเมอร์'
          })
        })

      localStorage.setItem('token', token.data.token)
    },
    // VERIFY PAYMENT GATEWAY
    async verifyPayment (bank_code, bank_number, amount, type) {
      // Get token from localStorage
      const token = localStorage.getItem('token')

      // Hide/show popups and set initial values
      this.popup_wd = false
      this.popup_payment = true
      this.payment_form.info = 'Loading...'
      this.payment_form.balance = 'Loading...'

      this.paymentType = type

      // Make an API request to verify payment
      await axios
        .post(`withdraw/transfer/payment/verify/${type}`, {
          bank_code,
          bank_number,
          amount,
          token
        })
        .then((response) => {
          // Handle the response from the API
          this.get_payment = response.data

          if (this.get_payment.status) {
            // Payment verification successful
            this.payment_form.balance = this.get_payment.data.balance

            if (this.get_payment.data) {
              // Display payment information if available
              this.payment_form.info = this.get_payment.data.info
              // Add Crypto network list
              if (this.get_payment.data.list) {
                this.cryptoList = this.get_payment.data.list
                this.cryptoNetwork = this.get_payment.data.list[0].network
              }
            }
          } else {
            // Payment verification failed
            this.popup_payment = false
            this.$vs.notify({
              time: 8000,
              color: 'danger',
              position: 'top-right',
              icon: 'error',
              title: 'เกิดข้อผิดพลาด',
              text: this.get_payment.message
            })
            this.clear_data()
            this.set_store()
          }
        })
    },
    // CONFIRM PAYMENT GATEWAY
    async confirmPayment (bank_code, username, amount, wd_id, type) {
      // Show loading spinner
      this.$vs.loading()

      // Get token from localStorage
      const token = localStorage.getItem('token')

      try {

        const requestData = {
          bank_code,
          amount,
          wd_id,
          token
        }

        if (type === 'crypto') {
          requestData.network = this.cryptoNetwork
        }

        // Make an API request to confirm payment verification
        const response = await axios.post(`withdraw/transfer/payment/confirm/${type}`, requestData)

        // Handle the response from the API
        this.status_wd = response.data

        if (this.status_wd.status) {
          // Payment confirmation successful
          this.$vs.loading.close()
          this.popup_payment = false

          // Show success notification
          this.$vs.notify({
            time: 3000,
            color: 'success',
            position: 'top-right',
            icon: 'check_box',
            title: 'ถอนเงินสำเร็จ',
            text: `ยูสเซอร์ ${username} จำนวนเงิน ${amount}`
          })

          // Clear data, update store, and fetch new data
          this.clear_data()
          this.set_store()
          this.get_data()
        } else {
          // Payment confirmation failed
          this.$vs.loading.close()
          this.popup_scb = false

          // Show error notification
          this.$vs.notify({
            time: 8000,
            color: 'danger',
            position: 'top-right',
            icon: 'error',
            title: 'ถอนเงินไม่สำเร็จ',
            text: this.status_wd.message
          })

          // Clear data and update store
          this.clear_data()
          this.set_store()
        }
      } catch (error) {
        // Handle any errors during the API request
        console.error('Error occurred during payment confirmation:', error)
      }
    }
  },
  async mounted () {
    await this.get_data()

    this.withdrawSettings = (
      await axios.get('settingsystem/settingobject/withdraw')
    ).data
  },
  sockets: {
    be_withdrawn_refresh () {
      this.get_data()
      if (this.webname.includes('SLOT')) {
        this.playSound()
      }
    }
  }
}
</script>

<style lang="scss" scooped>
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
div.ag-root .ag-cell-focus {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
</style>
